.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;

}
.about {
    padding-top: 5rem;
    height: 100vh;
}
.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;

}
.about__me img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
.about__me img:hover {
    transform: rotate(0);
}
.social__media{
   padding: 2rem;
   border-radius: 2rem;
   display: grid;
   grid-template-columns: repeat(3, 0.1fr);
   gap: 3rem;
   place-items: center;
   font-size: 2rem;
   justify-content: center;

}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
    }
    .about__me {
        width: 50%;
        margin: 2rem, auto 4rem
    }
    .about_content p{
        margin: 1rem 0 1.5rem;
    }
    .about{
        height: 120vh;
    }
}
@media screen and (max-width: 600px) {

    .about__me {
        width: 65%;
        margin: 0 auto 3rem
    }
    .about_content p{
        margin: 1.5rem 0;
    }
        .about{
            height: 140vh;
        }


}