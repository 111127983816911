.experience {
  
    height: 230vh;
    padding-top: 5rem;
}
.experience__content{
    display: flex;
    flex-direction: left;
    gap: 3rem;
    color: black;
}
.experience__img {

    background: white;
    border-radius: 50%;
    width: auto;
    height: 5em;
}
.experience__text h5 {
    color: black;
}
.experience__text h6 {
    font-style: italic;
}
.modal_right{
    float: right;
}
.modalImage {
    background: white;
    border-radius: 50%;
    width: auto;
    height: 3em;
}
.modal_title{
    display: flex;
    gap: 2rem;
    color: black;
}
.modal_date{
    font-size: small;
    font-weight: normal;
}
@media screen and (max-width: 1324px) {
    .experience__content{
        gap: 3rem;
    }
}
@media screen and (max-width: 1024px) {
    .experience {
        
        height: 150vh;
    }
}
@media screen and (max-width: 600px) {
    .experience {
        
        height: 320vh;
        padding-top: 5rem;
    }
    .experience__text h5 {
        font-size: 15px;
    }
    .experience__text h6 {
        font-size: 10px;
    }
    .experience__text {
        font-size: 15px;
        padding-bottom: 5rem;
    }
    .experience__content{
       
        flex-direction: column;
        grid-template-columns: 1fr;
        
    }
    .experience__img {

        background: white;
        border-radius: 50%;
        width: auto;
        height: 5em;
    }
    .vertical-timeline-element--work{
        justify-content: center;
        display: block;
    }
    .modal_right{
        padding-right: 3.5rem;
    }
}