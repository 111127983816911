.projects__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.project__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}

.project__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.project_image {
    border-radius: 1.5rem;
    overflow: hidden;
}
.project__item h3 {
    margin: 1.2rem 0 2rem;
}
.project__item h6 {
    margin: 0rem 0 1rem;
}
.project_cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
.pro {
    height: 150vh;
    padding-top: 5rem;
}
@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        justify-content: center;
    }
    .pro {
        height: 130vh;
    }
}
@media screen and (max-width: 600px) {

    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        justify-content: center;
    }
    .pro {
        padding-bottom: 15rem;
        height: 230vh;
    }
}